import PropTypes from 'prop-types'
import React from 'react'
import RTGLink from '@shared/link'
import rtgTo from '@helpers/rtg-to'
import { useTest } from '@hooks/useTest'
import { useSelector } from 'react-redux'

const FilterLink = ({ item, children, createURL, refine, toggleFilter }) => {
  const isMobile = useSelector(state => state.global.isMobile)
  const link = createURL(item.value)
  const { generateTestId } = useTest()

  const scrollToElementWithOffset = (element, offset) => {
    if (element) {
      const elementPosition = element.getBoundingClientRect().top + window.scrollY
      const offsetPosition = elementPosition - offset

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }

  return (
    <RTGLink
      onKeyPress={e => {
        if (e.keyCode === 32) {
          e.preventDefault()
          e.target.click()
        }
      }}
      onClick={e => {
        e.preventDefault()
        if (!isMobile) toggleFilter()
        refine(item.value)
        const productResultsWrapper = document.querySelector('#productResultsWrapper')
        if (productResultsWrapper) {
          productResultsWrapper.focus()
          scrollToElementWithOffset(productResultsWrapper, 150)
        }
      }}
      data={{
        rel: 'noopener noreferrer',
        [rtgTo(link)]: link,
        category: 'plp parts',
        action: 'filter link click',
        label: 'filter link',
      }}
      data-testid={generateTestId('refinement-list', `filter-link-${item.label}`)}
    >
      {children}
    </RTGLink>
  )
}

FilterLink.propTypes = {
  children: PropTypes.any,
  createURL: PropTypes.func,
  item: PropTypes.shape({
    isRefined: PropTypes.any,
    value: PropTypes.any,
    label: PropTypes.string,
  }),
  refine: PropTypes.func,
  toggleFilter: PropTypes.func,
}

export default FilterLink
