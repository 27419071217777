import PropTypes from 'prop-types'
import React, { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import Helmet from 'react-helmet'
import { Grid, Paper } from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material'
import CircleCounter from '@components/shared/circle-counter'
import { useTest } from '@hooks/useTest'
import SearchFilters from './search-filters'
import SearchSorting from './search-sorting'
import ConnectedHits from './connectors/ConnectedHits'
import ConnectedStats from './connectors/ConnectedStats'
import ConnectedNoResultsSearch from './connectors/ConnectedNoResultsSearch'

import ConnectedPagination from './connectors/ConnectedPagination'
import { SortFilter } from '../plp/search-results'
import { CustomConnectedCurrentRefinements } from './connectors/CustomCurrentRefinements'

const SearchContainerWrapper = styled('div')(({ theme }) => ({ backgroundColor: theme.palette.primary.light }))

const LeftBarWrapper = styled('div')(({ theme }) => ({
  display: 'none',
  backgroundColor: theme.palette.primary.light,
  height: 'fit-content',
  minWidth: '280px',
  padding: '16px',

  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}))

const Summary = styled(Grid)(({ theme }) => ({
  padding: 0,
  marginBottom: '20px',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

const SummaryTop = styled(Grid)(({ theme }) => ({
  margin: '10px -15px 0 -15px',
  width: 'calc(100% + 30px)',
  padding: '0 15px',
  borderBottom: '1px solid #dedede',
  borderTop: '1px solid #dedede',
  position: 'sticky',
  top: '58px',
  zIndex: 10,
  backgroundColor: '#fff',
  justifyContent: 'space-between',
  // [theme.breakpoints.down('md')]: {
  //   top: '130px',
  // },
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}))

const SearchMobileTitle = styled('span')`
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  color: #333;
  margin-top: 12px;
  margin-left: 10px;
`

const Wrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}))

const SidebarMobileWrapper = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  min-width: 280px;
  min-height: 100%;
  background-color: #f6f6f6;
  padding: 16px 10px;
`

const MobileHeaderWrapper = styled(Grid)`
  margin-bottom: 24px;
`

const CloseButton = styled('button')`
  display: grid;
  place-content: center;
  align-self: flex-start;
  color: #333;
`

const SidebarMobileModalWrapper = styled('div')(({ theme, openModalSidebar }) => ({
  display: 'none',
  position: 'fixed',
  zIndex: 4000,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  overflow: 'auto',
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  [theme.breakpoints.down('md')]: {
    display: `${openModalSidebar ? 'block' : 'none'}`,
  },
}))

const SearchResultsWrapper = styled(Paper)(() => ({
  flexGrow: 1,
}))

const SearchResults = props => {
  const { displayFilters, matchPath, searchState } = props
  const [openModalSidebar, setOpenModalSidebar] = useState(false)
  const { generateTestId } = useTest()

  const countFilters = useCallback(
    () =>
      Object.values(searchState.refinementList || {}).reduce((acc, next) => {
        if (next instanceof Array) {
          return acc + next.length
        }

        return next ? acc + 1 : acc
      }, 0),
    [searchState],
  )

  const filtersQuantity = countFilters()

  const closeModal = useCallback(e => {
    const isOutsideModal = Array.from(e.target.classList)
      .join(' ')
      .includes('SidebarMobileModalWrapper')
    if (isOutsideModal) {
      setOpenModalSidebar(false)
    }
  }, [])

  return (
    <SearchContainerWrapper className="search-results">
      {matchPath.includes('/search') && (
        <>
          <Helmet title="Search Results - Rooms To Go" />
          <h1 className="home-heading">Search Results</h1>
        </>
      )}
      <Wrapper>
        {/* Filters + Stats */}
        <SummaryTop container id="header-filters-search" justifyContent="space-between" alignItems="center">
          <ConnectedStats absolutePosition={false} />
          <SortFilter
            onClick={() => setOpenModalSidebar(true)}
            data-testid={generateTestId('search-results', 'sort-filter-btn')}
          >
            <span>Sort &amp; Filter</span>
            {filtersQuantity > 0 && <CircleCounter quantity={filtersQuantity} />}
          </SortFilter>
        </SummaryTop>

        {/* MOBILE */}
        <SidebarMobileModalWrapper openModalSidebar={openModalSidebar} onClick={closeModal}>
          <SidebarMobileWrapper>
            <MobileHeaderWrapper container justifyContent="space-between" alignItems="flex-end">
              <SearchMobileTitle>Sort &amp; Filter</SearchMobileTitle>
              <CloseButton
                type="button"
                title="Clear the search query."
                hidden=""
                onClick={() => setOpenModalSidebar(false)}
                tabIndex={0}
              >
                <CloseIcon />
              </CloseButton>
            </MobileHeaderWrapper>
            <SearchSorting />
            <SearchFilters attributes={displayFilters} matchPath={matchPath} />
          </SidebarMobileWrapper>
        </SidebarMobileModalWrapper>

        {/* DESKTOP */}
        <LeftBarWrapper>
          <Summary container justifyContent="space-between" alignItems="center">
            <ConnectedStats absolutePosition={false} />
          </Summary>
          <SearchSorting />
          <SearchFilters attributes={displayFilters} matchPath={matchPath} />
        </LeftBarWrapper>

        <SearchResultsWrapper container>
          <CustomConnectedCurrentRefinements searchState={searchState} />
          <ConnectedHits />
          <div className="pagination">
            <ConnectedPagination padding={2} />
          </div>
        </SearchResultsWrapper>
      </Wrapper>
      <ConnectedNoResultsSearch />
    </SearchContainerWrapper>
  )
}

SearchResults.propTypes = {
  displayFilters: PropTypes.any,
  matchPath: PropTypes.string,
  searchState: PropTypes.object,
}

export default SearchResults
