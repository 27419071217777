import React from 'react'
import { array, string } from 'prop-types'
import { orderBy, isEqual } from 'lodash'
import { connectStateResults } from 'react-instantsearch-dom'

import { removeFirstAndLastSlash } from '@helpers/string-helper'
import '@assets/css/components/search/search-filters.sass'
import { styled } from '@mui/material'
import ConnectedRefinementList from './connectors/ConnectedRefinementList'

const SearchFiltersWrapper = styled('div')(({ theme }) => ({
  // [theme.breakpoints.down('md')]: {
  //   marginBottom: '1rem',
  // },
}))

const FiltersWrapper = styled('div')(() => ({
  flexDirection: 'column',
  '&:active, &:focus': {
    outline: 'none',
  },
}))

const SearchFilters = ({ attributes, matchPath }) => {
  let pathname = ''
  if (typeof window !== 'undefined') {
    pathname = window.location.pathname
  }
  pathname = removeFirstAndLastSlash(pathname || matchPath)

  return (
    <SearchFiltersWrapper>
      <FiltersWrapper role="navigation" aria-label="Filter Results" tabIndex="-1">
        {attributes &&
          attributes.map(attribute => (
            <ConnectedRefinementList
              key={attribute}
              attribute={attribute}
              transformItems={items => orderBy(items, ['label', 'count'], ['asc', 'desc'])}
              operator="or"
              pathname={pathname}
              limit={100}
            />
          ))}
      </FiltersWrapper>
    </SearchFiltersWrapper>
  )
}

function searchFiltersShouldUpdate(prevState, nextState) {
  const { attributes: nextAttributes, matchPath: nextMatchPath, searchResults: nextSearchResults } = nextState
  const { attributes, matchPath, searchResults } = prevState

  return [prevState, nextState]
}

const MemoizedSearchFilters = React.memo(SearchFilters, searchFiltersShouldUpdate)

SearchFilters.propTypes = {
  attributes: array,
  matchPath: string,
}

export default connectStateResults(MemoizedSearchFilters)
